import { ChildModel, ModelBase } from 'core/store/model-base';
import { Product } from './product';
import { Availability } from 'core/constants';
import { Promotion } from './promotion';
import { PriceOption } from './price-option';

export class ProductCard extends Product {
    public static storeName: string = 'productCards';

    public image: Image;
    public prices: PriceOption[];
    public skuCode: string;
    public status: Availability;

    // These are for BYOD product
    public isByod: boolean;
    public oneTimePrice: string;
    public recurringPrice: string;
    public productLink: string;
    public hasExternalUrl: boolean;
    public enableByodPromo: boolean;
    public byoLabel: string;
    public byoPricingLabel: string;
    public byoCartImage: Image;
    public byoCheckLabelDevice: string;
    public byoCheckLabelWatch: string;
    public highestEPPromoWithTip: Promotion;
    public highestEPPromowithTipAndDDA: Promotion;
    public highestEPPromoWithDDA: Promotion;

    public price: PriceOption;
    public financeTerm: number;
    public downPayment: number;
    public retailPrice: PriceOption;

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'price',
            model: PriceOption
        }];
    }

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'prices',
            model: PriceOption
        }];
    }

    public static create<T extends ModelBase>(initData: object): T {
        const toReturn: ProductCard = <ProductCard> super.create<ProductCard>(initData);

        toReturn.price = toReturn.prices.length ? toReturn.prices[0] : toReturn.price;
        toReturn.retailPrice = toReturn.prices.find((priceOption: PriceOption) => priceOption.isFull);
        toReturn.prices.forEach((priceOption: PriceOption) => {
            if (priceOption.isFinanced) {
                toReturn.price = priceOption;
                toReturn.financeTerm = priceOption.term;
                toReturn.downPayment = priceOption.downPayment;
                toReturn.highestEPPromoWithTip = priceOption.promotions?.length && !toReturn.isTablet ? priceOption.highestEPPromoWithTip : undefined;
                toReturn.highestEPPromowithTipAndDDA = priceOption.promotions?.length && !toReturn.isTablet ? priceOption.highestEPPromoWithTipAndDDA : undefined;
                toReturn.highestEPPromoWithDDA = priceOption.promotions?.length && !toReturn.isTablet ? priceOption.highestEPPromoWithDDA : undefined;
            }
        });

        return <T> <any> toReturn;
    }

    public get prepaidPromo(): PriceOption {
        return this.price.isPrepaidPromo ? this.price : undefined;
    }

    public get extendedPromo(): Promotion {
        return this.price.isExtendedPromo ? this.price.extendedPromo : undefined;
    }

    public get extendedPromo2(): Promotion {
        return this.price.isExtendedPromo && (this.price.extendedPromo.isExtendedPromoDollar || this.price.extendedPromo.isExtendedPromoPCT) ? this.price.extendedPromo : undefined;
    }

    public get displayPromoValue(): string {
        const promotionalValue: number = this.price.promotionalValue;
        if (this.price.isDollarDiscount || this.price.isPrepaidPromo || this.price.isExtendedPromo) {
            return `$${promotionalValue}`;
        } else if (this.price.isPercentPromo) {
            return `${promotionalValue}%`;
        }
    }

    public get isPreorder(): boolean {
        return this.status === Availability.PREORDER;
    }
}
