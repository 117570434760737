import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { Subscription } from 'rxjs';

import { SitecoreComponent } from 'components/shared/sitecore';
import { Cloudinary, WindowReference } from 'core/services';
import { Util } from 'core/utils/util';

@Component({
    selector: 'hero-banner',
    styleUrls: [ './hero-banner.scss' ],
    templateUrl: './hero-banner.html'
})
export class MarketingHeroBannerComponent extends SitecoreComponent implements OnInit, OnDestroy {
    @Input() public rendering: ComponentRendering;

    public pageData: HeroBannerPageData;
    public textAlignment: string;
    public bannerheight: string;
    public breakpointImages: MediaImageOptions;
    public headerIcon: MediaImageOptions;
    public footerIcon: MediaImageOptions;
    public backgroundImgStyles: HeroBannerBackground = {};
    public textContainerClass: string = '';
    public linkClass: string;
    public themeClass: string;

    private windowReference: WindowReference;
    private subscriptions: Subscription[] = [];

    constructor(injector: Injector, windowReference: WindowReference) {
        super(injector);

        Object.assign(this, { windowReference });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<HeroBannerPageData>(this.rendering.fields);

        this.textAlignment = this.pageData.textAlign || 'align-left';
        this.bannerheight = this.pageData.bannerheight || '';
        this.setBannerProp();
        if (this.pageData.backgroundImage) {
            this.breakpointImages = Cloudinary.generateMediaOptionsFromCms(this.pageData.backgroundImage);
        }

        if (this.pageData.headerIcon) {
            this.headerIcon = Cloudinary.generateMediaOptionsFromCms(this.pageData.headerIcon);
        }

        if (this.pageData.footerIcon) {
            this.footerIcon = Cloudinary.generateMediaOptionsFromCms(this.pageData.footerIcon);
        }

        if (this.pageData.textContainerClass) {
            this.textContainerClass = this.pageData.textContainerClass;
        }

        this.subscriptions.push(this.windowReference.breakpoint.subscribe((breakpoint: Breakpoint) => {
            this.backgroundForBreakpoint(breakpoint);
        }));
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public getClassName(): object {
        if (this.pageData.textContainerClass) {
            const obj = {};
            obj[this.pageData.textContainerClass] = true;

            return obj;
        }

        return undefined;
    }

    public setBannerProp(): void {
        this.themeClass = this.pageData.textBoxTheme;
        if (this.themeClass === 'dark-grey') {
            this.linkClass = 'dark-grey';
        } else if (this.themeClass === 'dark') {
            this.linkClass = 'dark';
        } else if (this.themeClass === 'light') {
            this.linkClass = 'light';
        } else {
            this.linkClass = 'light-grey';
        }
    }

    private backgroundForBreakpoint(breakpoint: Breakpoint): void {
        if (!this.pageData.backgroundImage) {
            return;
        }
        this.backgroundImgStyles = {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${Cloudinary.pickBreakpointImage(this.breakpointImages, breakpoint, { width: 0, height: 0 })})`
        };
    }
}
