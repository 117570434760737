import { AfterViewInit, Component, ElementRef, Injector, Input, OnInit } from '@angular/core';
import { CimaToken, Cloudinary } from 'core/services';
import { Subscription } from 'rxjs';
import { WindowReference } from 'services/window';
import { ProductCard } from 'core/store/catalog/model/card';
import { SitecoreComponent } from 'components/shared/sitecore';
import { Decorator } from 'services/decorator';
import { SafeUrl } from '@angular/platform-browser';
import { XmCurrencyPipe } from 'components/shared/pipes/currency';
import { MarketingExtendedPromo, MarketingProductCard } from 'src/typings/product-carousel';

@Component({
    selector: 'xm-product-card',
    styleUrls: [ './card.scss' ],
    templateUrl: './card.html',
    preserveWhitespaces: true
})
export class ProductCardComponent extends SitecoreComponent implements OnInit, AfterViewInit {
    private static SINGLE_LINE_CSS: string = 'single-line-ellipsis';
    private static MULTI_LINE_CSS: string = 'multi-line-ellipsis';

    @Input() public product: MarketingProductCard;
    @Input() public pageData: MarketingProductCard;
    @Input() public extendedPromos: MarketingExtendedPromo[];

    @Input() public tps: string;

    public isSingleLineEllipsis: boolean;
    public isMultiLineEllipsis: boolean;
    public activeColor: ProductColor;
    public imageOptions: MediaImageOptions;
    public promoIcon: MediaImageOptions;
    public productLink: SafeUrl;
    public downPaymentText: string = '';
    public promoLabel: string;
    public hideStrikeThrough: boolean = false;
    public abTestExperience: boolean;
    public preOrderText: string;
    public priceAfterPromotion: string;
    public priceBeforePromotion: string;
    public oneTimePrice: string;
    public currencyPipe: XmCurrencyPipe;
    public breakpointImages: MediaImageOptions;
    public disableCta: boolean = false;
    public productsDetail: ProductCard[] = [];

    private epContent: MarketingExtendedPromo;
    private element: ElementRef;
    private name: Element;
    private window: WindowReference;
    private subscriptions: Subscription[] = [];

    constructor(injector: Injector, window: WindowReference, cimaToken: CimaToken, element: ElementRef, decorator: Decorator, currencyPipe: XmCurrencyPipe) {
        super(injector);

        Object.assign(this, { window, cimaToken, element, decorator, currencyPipe });
    }

    public ngOnInit(): void {
        if (!this.product) {
            return;
        }
      
        if (this.product.extendedPromo && this.extendedPromos) {
            this.epContent = this.extendedPromos.find((promo: MarketingExtendedPromo) => promo.promoId === this.product.extendedPromo.id);
        }

        this.buildContent();

        //this.activeColor = this.product.colors ? this.product.colors[0] : undefined;

        if (this.product.productImage) {
            this.breakpointImages = this.product.productImage;
        }

        this.subscriptions.push(this.window.windowWidth.subscribe(() => {
            this.ellipsis();
        }));
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.name = this.element.nativeElement.querySelector('.name');
            this.ellipsis();
        });
    }

    public shop(): void {
        const pdpUrl = this.product.ctaLink.replace('{deviceType}', this.product.deviceType.value).replace('{slugName}', this.product.productIdentifier);
        window.location.href = pdpUrl;
    }

    private buildContent(): void {
        if (this.product.byod || this.product.productIdentifier) {
            this.disableCta = false;
        } else {
            this.disableCta = true;
        }
        this.preOrderText = this.product.preOrder ? this.product.preOrderText : '';
        this.priceAfterPromotion = this.product.priceAfterPromotion ? this.currencyPipe.transform(Number(this.product.priceAfterPromotion)) : this.currencyPipe.transform(Number(this.product.priceBeforePromotion));
        this.priceBeforePromotion = this.currencyPipe.transform(Number(this.product.priceBeforePromotion));
        this.oneTimePrice = this.currencyPipe.transform(Number(this.product.onetimePrice));
        if (this.product.prepaidPromo) {
            this.promoIcon = Cloudinary.generateMediaOptionsFromCms(this.pageData.prepaidImage);
            this.promoLabel = this.pageData.prepaidLabel;
        } else if (this.product.extendedPromo && this.epContent) {
            this.promoIcon = Cloudinary.generateMediaOptionsFromCms(this.epContent.labelIcon);
            //this.promoLabel = this.product.extendedPromo.replaceStringWithPromoValue(this.epContent.shortDeviceLabel);
        }
    }

    private ellipsis(): void {
        if (!this.name) {
            return;
        }

        // reset the name so we can calculate the ellipsis again
        this.name.innerHTML = this.product.deviceName;

        if (this.promoLabel) {
            this.name.classList.add(ProductCardComponent.SINGLE_LINE_CSS);
        } else {
            this.name.classList.remove(ProductCardComponent.MULTI_LINE_CSS);

            if (this.name.scrollHeight > this.name.clientHeight) {
                this.name.classList.add(ProductCardComponent.MULTI_LINE_CSS);

                // now check if the name goes beyond two lines
                if (this.name.scrollHeight > this.name.clientHeight) {
                    const wordArray: string[] = this.name.innerHTML.split(' ');
                    while (this.name.scrollHeight > this.name.clientHeight && wordArray.length) {
                        wordArray.pop();
                        this.name.innerHTML = `${wordArray.join(' ')}...`;
                    }
                }
            }
        }
    }
}
