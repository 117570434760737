import { Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { Subscription } from 'rxjs';

import { SitecoreComponent } from 'components/shared/sitecore';
import { WindowReference } from 'services/window';
import { Util } from 'core/utils/util';
import { SectionContainer } from 'core/constants';

@Component({
    selector: 'card-module',
    styleUrls: [ './cards.scss' ],
    templateUrl: './cards.html'
})
export class MarketingCardsComponent extends SitecoreComponent implements OnInit, OnDestroy {
    @Input() public rendering: ComponentRendering;

    @ViewChild(SwiperDirective) public swiper: SwiperDirective;

    public carouselSettings: SwiperConfigInterface = {
        slidesPerView: 'auto',
        spaceBetween: 0,
        centeredSlides: true,
        allowTouchMove: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'bullets',
            bulletElement: 'button'
        },
        breakpoints: {
            768: {
                spaceBetween: 0,
                centeredSlides: false,
                allowTouchMove: false
            },
            960: {
                spaceBetween: 0,
                centeredSlides: false,
                allowTouchMove: false
            }
        }
    };

    public inputs: CardInputs;
    public breakpoint: Breakpoint;
    public sectionContainer: string;
    public pageData: MarketingCardPageData;

    private subscriptions: Subscription[] = [];
    private windowReference: WindowReference;

    constructor(injector: Injector, windowReference: WindowReference) {
        super(injector);

        Object.assign(this, { windowReference });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<MarketingCardPageData>(this.rendering.fields);
        this.sectionContainer = this.pageData.sectionContainer || '';

        this.subscriptions.push(this.windowReference.breakpoint.subscribe((breakpoint: Breakpoint) => {
            this.breakpoint = breakpoint;
            this.setCardSize(breakpoint);
        }));
        
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public placeholderLoaded(): void {
        if (this.swiper) {
            this.swiper.update();
        }
    }

    private setCardSize(breakpoint: Breakpoint): void {
        const cardCount: number = this.rendering.placeholders.cardsModuleCard.length;

        this.inputs = {
            isSmall: false,
            isMedium: false,
            isLarge: false,
            isOverviewContainer: this.sectionContainer === SectionContainer.FIND_SECTION
        };

        if (breakpoint.isSmall) {
            this.inputs.isMedium = true;
        } else if (breakpoint.isMedium) {
            this.inputs.isLarge = true;
        } else if (breakpoint.isLarge) {
            if (cardCount <= 3) {
                this.inputs.isMedium = true;
            } else if (cardCount >= 4) {
                this.inputs.isSmall = true;
            }
        } else if (breakpoint.isXlarge) {
            if (cardCount <= 3) {
                this.inputs.isLarge = true;
            } else if (cardCount >= 4) {
                this.inputs.isMedium = true;
            }
        }
    }
}
