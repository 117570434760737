import { Ng2StateDeclaration } from '@uirouter/angular';
import { Rejection, StateService, Transition } from '@uirouter/core';

import { AbTestService, Analytics, CimaCore, CimaUrl, CmsService, DataLayer, Seo, TrialService, WindowReference, XmStore, XmStoreUtil } from 'core/services';
import { StoreAction } from 'core/store-actions';
import { CmsPageData } from 'core/store/cms/model/cms';

import { IXMOptions } from 'core/interfaces';
import { CONFIG_TOKEN, ParamType, TARGET_PAGE } from 'core/constants';

import { MarketingAppComponent } from 'components/app/app';
import { MarketingBaseComponent } from 'components/base/base';
import { CoreRoute } from 'core/route';
import { VisitorSessionService } from 'services/log/visitor-session-service';
import { NGXLogger } from 'ngx-logger';
import { XmShopHealthCheckComponent } from 'components/healthcheck/healthcheck';

export function AppInit(coreRoute: CoreRoute, visitorSessionService: VisitorSessionService): Promise<void> {
    coreRoute.init();
    visitorSessionService.init();

    return Promise.resolve();
}

export function LoadCimaTokens(cimaCore: CimaCore): Promise<string | void> {
    return cimaCore.loadCimaToken().then((token: string) => {
        cimaCore.tryPassiveAuthLogin();

        return token;
    });
}

export function LoadAnalytics(analytics: Analytics): Promise<void> {
    return analytics.loadAnalytics();
}

export function LoadDataLayer(dataLayer: DataLayer): Promise<void> {
    return dataLayer.loadDataLayer();
}

export function LoadTargetAnalytics(abTestService: AbTestService): Promise<string> {
    return abTestService.loadTarget();
}

export function LoadTargetData(xmStore: XmStore, cms: CmsService): Promise<void> {
    return XmStoreUtil.defaultCatch(xmStore.findPromise<CmsPageData>(StoreAction.GET_CMS_PAGE_DATA, CmsPageData, {
        filters: { pageId: TARGET_PAGE },
        params: { pageId: TARGET_PAGE }
    }).then((response: CmsPageData) => cms.setTargetData(response.sitecorePage)));
}

export function LoadTrials(transition: Transition, trial: TrialService): Promise<void> {
    trial.init(transition.params().TRIAL_ID);

    return Promise.resolve();
}

export function GetSitecorePageData(xmStore: XmStore, transition: Transition, seo: Seo, state: StateService): Promise<void | CmsPageData> {
    const pageId: string = transition.params().page_id;

    return xmStore.findPromise<CmsPageData>(StoreAction.GET_CMS_PAGE_DATA, CmsPageData, {
        filters: { pageId },
        params: { pageId }
    }).catch(() => {
        if (WindowReference.isWindowAvailable && !window.navigator.userAgent.includes('Prerender')) {
            state.go('404', {}, { location: false });
        }
        seo.addPrerender404Tag();
        transition.abort();
    });
}

export function GetSitecore404PageData(xmStore: XmStore, transition: Transition, seo: Seo): Promise<void | CmsPageData> {
    const pageId: string = transition.params().page_id;

    return xmStore.findPromise<CmsPageData>(StoreAction.GET_CMS_PAGE_DATA, CmsPageData, {
        filters: { pageId },
        params: { pageId }
    }).catch(() => {
        seo.addPrerender404Tag();
        transition.abort();
    });
}

export function AuthLogout(cimaUrl: CimaUrl): Promise<void> {
    cimaUrl.redirectToLogout();

    return Promise.reject(Rejection.aborted('state never allowed to enter'));
}

export function ResolveTarget(transition: Transition, config: IXMOptions): Promise<void> {
    if (transition.params().page_id === TARGET_PAGE && !config.SHOW_TARGET) {
        return Promise.reject();
    }

    return Promise.resolve();

}

export const MARKETING_ROUTES: Ng2StateDeclaration[] = [
    {
        abstract: true,
        name: 'marketing',
        url: '?INTCMP&CMP&TRIAL_ID&data_option',
        params: {
            CMP: {
                type: ParamType.encoded
            },
            INTCMP: {
                type: ParamType.encoded
            },
            TRIAL_ID: {
                type: ParamType.encoded
            },
            data_option: {
                type: ParamType.encoded
            }
        },
        resolve: [
            {
                token: 'appLoad',
                deps: [ CoreRoute, VisitorSessionService ],
                resolveFn: AppInit
            },
            {
                token: 'loadCimaToken',
                deps: [ CimaCore ],
                resolveFn: LoadCimaTokens
            },
            {
                token: 'trialsInit',
                deps: [ Transition, TrialService ],
                resolveFn: LoadTrials
            },
            {
                token: 'analyticsInit',
                deps: [ Analytics ],
                resolveFn: LoadAnalytics
            },
            {
                token: 'dataLayerInit',
                deps: [ DataLayer ],
                resolveFn: LoadDataLayer
            },
            {
                token: 'loadTargetAnalytics',
                deps: [ AbTestService, 'analyticsInit', 'dataLayerInit' ],
                resolveFn: LoadTargetAnalytics
            },
            {
                token: 'loadTargetData',
                deps: [ XmStore, CmsService ],
                resolveFn: LoadTargetData
            },
            {
                token: 'resolveTarget',
                deps: [ Transition, CONFIG_TOKEN, 'trialsInit', StateService ],
                resolveFn: ResolveTarget
            }
        ],
        component: MarketingAppComponent
    },
    {
        name: '404',
        params: {
            page_id: 'page-not-found'
        },
        resolve: [
            {
                token: 'sitecorePageData',
                deps: [ XmStore, Transition, Seo, StateService, NGXLogger ],
                resolveFn: GetSitecore404PageData
            }
        ],
        component: MarketingBaseComponent
    },
    {
        name: 'default',
        url: '/learn/mobile',
        params: {
            page_id: 'overview'
        },
        resolve: [
            {
                token: 'sitecorePageData',
                deps: [ XmStore, Transition, Seo, StateService, NGXLogger ],
                resolveFn: GetSitecorePageData
            }
        ],
        component: MarketingBaseComponent
    },
    {
        name: 'healthcheck',
        url: '/healthcheck',
        component: XmShopHealthCheckComponent
    },
    {
        name: 'logout',
        url: '/logout',
        resolve: [
            {
                token: 'cimaLogout',
                deps: [ CimaUrl ],
                resolveFn: AuthLogout
            }
        ]
    },
    {
        name: 'marketing.base',
        url: '/learn/mobile/:page_id?zip',
        params: {
            page_id: {
                type: 'string',
                raw: true
            },
            zip: {
                type: 'string',
                dynamic: true
            }
        },
        resolve: [
            {
                token: 'sitecorePageData',
                deps: [ XmStore, Transition, Seo, StateService, NGXLogger ],
                resolveFn: GetSitecorePageData
            }
        ],
        component: MarketingBaseComponent
    }
];
