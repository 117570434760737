<section class="{{theme}}" *ngIf="hasFieldOrEditable(field)">
    <div *ngIf="field && field.editable" [innerHtml]="field.editable | safeHtml"></div>
    <ng-container *ngIf="field && !field.editable">
        <span class="{{customClass}}" *ngFor="let cmsReplace of cmsReplaces">
            <span [innerHtml]="cmsReplace.beforeText | safeHtml"></span>
            <a *ngIf="cmsReplace.internalLink"
                [ngClass]="{ 'xm-btn-primary-light': cmsReplace.displayAsPrimary, 'xm-btn-secondary-light': cmsReplace.displayAsSecondary, 'cb-byow-link': cmsReplace.displayByowLink, 'cb-byow-button': cmsReplace.displayByowButton, 'cb-plan-details-link': cmsReplace.displayPlanDetailsLink, 'xm-btn-link-light': cmsReplace.displayAsLink, 'inline-text-replace': cmsReplace.useParentStyles, 'overview-cta-primary': overview && cmsReplace.displayAsPrimary, 'overview-cta-secondary': overview && cmsReplace.displayAsSecondary, 'talk-with-specialist': talkWithSpecialist }"              
                [uiSref]="cmsReplace.linkAction"
                [uiParams]="cmsReplace.linkParams"
                [attr.aria-label]="cmsReplace.linkAccessibility"
                [innerHtml]="cmsReplace.linkName | safeHtml"
                [attr.target]="cmsReplace.linkTarget"
                [attr.banner]="cmsReplace.linkAnalytics">
            </a>
            <a *ngIf="cmsReplace.externalLink || cmsReplace.inAppUrl"
                [ngClass]="{ 'xm-btn-primary-light': cmsReplace.displayAsPrimary, 'xm-btn-secondary-light': cmsReplace.displayAsSecondary, 'cb-byow-link': cmsReplace.displayByowLink, 'cb-byow-button': cmsReplace.displayByowButton,  'cb-plan-details-link-secondary': cmsReplace.displayPlanDetailsLinkSecondary, 'cb-plan-details-link': cmsReplace.displayPlanDetailsLink, 'xm-btn-link-light': cmsReplace.displayAsLink, 'inline-text-replace': cmsReplace.useParentStyles, 'overview-cta-primary': overview && cmsReplace.displayAsPrimary, 'overview-cta-secondary': overview && cmsReplace.displayAsSecondary,'talk-with-specialist': talkWithSpecialist }"
                [href]="safeUrl(cmsReplace.linkAction)"
                [attr.aria-label]="cmsReplace.linkAccessibility"
                [innerHtml]="cmsReplace.linkName | safeHtml"
                target="{{ cmsReplace.linkTarget }}"
                [attr.banner]="cmsReplace.linkAnalytics">
            </a>
        </span>
        <span  class="{{customClass}}" *ngIf="!menuHeader" [ngClass]="{'dropdown-mainmenu-link': applyDropdownTextStyle}" [innerHtml]="afterText | safeHtml"></span>
        <button *ngIf="menuHeader" class="dropdown-mainmenu-link" [innerHtml]="afterText | safeHtml">          
        </button>
    </ng-container>
</section>
