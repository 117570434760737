import { Component, ElementRef, HostListener, Inject, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { SitecoreComponent } from 'components/shared/sitecore';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss';
import { ProductCard } from 'core/store/catalog/model/card';
import { XmStore, XmStoreUtil } from 'services/store';
import { Catalog } from 'core/store/catalog/model/catalog';
import { StoreAction } from 'core/store-actions';
import { Util } from 'core/utils/util';
import { Cloudinary } from 'services/cloudinary';
import { LocalStorage } from 'core/services';
import { Availability, BreakpointWidth, CONFIG_TOKEN, ShopCategory, StorageToken } from 'core/constants';
import { MarketingCarouselData, MarketingProductCard } from 'src/typings/product-carousel';
import { PriceOption } from 'core/store/catalog/model/price-option';
import { IXMOptions } from 'core/interfaces';
import { Promotion } from 'core/store/catalog/model/promotion';

@Component({
    selector: 'xm-product-carousel',
    styleUrls: ['./carousel.scss'],
    templateUrl: './carousel.html'
})
export class ProductCarouselComponent extends SitecoreComponent implements OnInit, OnDestroy {
    public static DEVICE_LIMIT: number = 12;

    @ViewChild('prevButton', { static: true, read: ElementRef }) public swiperPrevButton: ElementRef<HTMLElement>;
    @ViewChild('nextButton', { static: true, read: ElementRef }) public swiperNextButton: ElementRef<HTMLElement>;

    @Input() public rendering: ComponentRendering;
    @Input() public slug: string;

    public products: MarketingProductCard[] = [];
    public allProducts: MarketingProductCard[] = [];
    public productsToSourcePCAT: MarketingProductCard[] = [];
    public devices: ProductCard[] = [];
    public tablets: ProductCard[] = [];
    public watches: ProductCard[] = [];
    public showNewCard: boolean = false;
    public leftArrowIcon: MediaImageOptions;
    public rightArrowIcon: MediaImageOptions;
    public pageData: MarketingCarouselData;
    public swiperConfig: SwiperConfigInterface;
    public productCardPromo: Promotion;

    public byodProductCard: ProductCard;
    public tps: string;
    public isMobileView: boolean;
    public isTabletView: boolean;
    public isSmallDesktopView: boolean;
    public isLargeDesktopView: boolean;
    public showArrows: boolean = false;
    public showLentils: boolean = false;
    public config: IXMOptions;

    private subscriptions: Subscription[] = [];
    private xmStore: XmStore;
    private localStorage: LocalStorage;

    constructor(@Inject(CONFIG_TOKEN) config: IXMOptions, injector: Injector, xmStore: XmStore, localStorage: LocalStorage) {
        super(injector);

        Object.assign(this, { config, xmStore, localStorage });
    }

    @HostListener('window:resize', [ '$event' ])
    public sizeChange(): void {
        this.buildCarouselWithLentils();
    }

    public ngOnInit(): void {
        this.tps = this.localStorage.get(StorageToken.TPS);
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.query<Catalog>(StoreAction.GET_CATALOG, { category: ShopCategory.DEVICE, limit: ProductCarouselComponent.DEVICE_LIMIT - 1, tps: this.tps }),
            (catalog: Catalog) => {
                this.devices = catalog.products;
                this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.query<Catalog>(StoreAction.GET_CATALOG, { category: ShopCategory.TABLET, limit: ProductCarouselComponent.DEVICE_LIMIT - 1, tps: this.tps }),
                    (catalog: Catalog) => {
                        this.tablets = catalog.products;
                        this.devices.push(...this.tablets);
                        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.query<Catalog>(StoreAction.GET_CATALOG, { category: ShopCategory.WATCH, limit: ProductCarouselComponent.DEVICE_LIMIT - 1, tps: this.tps }),
                            (catalog: Catalog) => {
                                this.watches = catalog.products;
                                this.devices.push(...this.watches);
                                this.getCatalog();
                            }, () => {
                                this.watches = [];
                            }));
                    }, () => {
                        this.tablets = [];
                    }));
            }, () => {
                this.devices = [];
            }));

        this.pageData = this.flattenFields<MarketingCarouselData>(this.rendering.fields);
        if (this.pageData.leftArrow) {
            this.leftArrowIcon = Cloudinary.generateMediaOptionsFromCms(this.pageData.leftArrow);
        }

        if (this.pageData.rightArrow) {
            this.rightArrowIcon = Cloudinary.generateMediaOptionsFromCms(this.pageData.rightArrow);
        }
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    private getCatalog(): void {
        this.allProducts = Array.isArray(this.pageData.productCard) ? this.pageData.productCard : [this.pageData.productCard];

        this.allProducts.forEach((product: MarketingProductCard) => {
            if (product.isSourcedFromCMS) {
                this.products.push(product);
            } else if (!product.isSourcedFromCMS && product.productIdentifier) {
                this.devices.forEach((item: ProductCard) => {
                    if (item.slug === product.productIdentifier) {
                        this.buildMarketingProductCard(product, item);
                        this.products.push(product);
                    }
                });
            }
        });

        this.buildCarouselWithLentils();
    }

    private buildCarouselWithLentils(): void {
        const width = window.innerWidth;
        this.isMobileView = width < BreakpointWidth.MEDIUM;
        this.isTabletView = width >= BreakpointWidth.MEDIUM && width < BreakpointWidth.LARGE;
        this.isSmallDesktopView = width >= BreakpointWidth.LARGE && width < BreakpointWidth.XLARGE;
        this.isLargeDesktopView = width >= BreakpointWidth.XLARGE;

        if (!this.products.length) {
            return;
        }
        if ((this.products.length > 4 && this.isLargeDesktopView) || (this.products.length > 3 && this.isSmallDesktopView)) {
            this.swiperConfig = {
                init: true,
                slidesPerView: 1,
                spaceBetween: 0,
                allowTouchMove: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    bulletElement: 'button',
                    clickable: true
                },
                navigation: {
                    prevEl: '.swiper-navigation-prev',
                    nextEl: '.swiper-navigation-next'
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        slidesPerGroup: 2
                    },
                    960: {
                        slidesPerView: 3,
                        slidesPerGroup: 3
                    },
                    1440: {
                        slidesPerView: 4,
                        slidesPerGroup: 4
                    }
                }
            }
            this.showArrows = true;
            this.showLentils = true;
        } else if ((this.products.length > 2 && this.isTabletView) || (this.products.length > 1 && this.isMobileView)) {
            this.swiperConfig = {
                init: true,
                slidesPerView: 1,
                spaceBetween: 0,
                allowTouchMove: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    type: 'bullets',
                    bulletElement: 'button'
                },
                navigation: {
                    prevEl: '.swiper-navigation-prev',
                    nextEl: '.swiper-navigation-next'
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        slidesPerGroup: 2
                    },
                    960: {
                        slidesPerView: 3,
                        slidesPerGroup: 3
                    },
                    1440: {
                        slidesPerView: 4,
                        slidesPerGroup: 4                           
                    }
                }
            }
            this.showArrows = true;
            this.showLentils = true;
        } else {
            this.swiperConfig = {
                init: true,
                slidesPerView: 1,
                spaceBetween: 0,
                allowTouchMove: true,
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        slidesPerGroup: 2
                    },
                    960: {
                        slidesPerView: 3,
                        slidesPerGroup: 3
                    },
                    1440: {
                        slidesPerView: 4,
                        slidesPerGroup: 4
                    }
                }
            }
            this.showArrows = false;
            this.showLentils = false;
        }
    }

    private buildMarketingProductCard(product: MarketingProductCard, item: ProductCard): MarketingProductCard {
        // whenever making changes in the promo display logic in buy app make sure to do the changes here.
        if (this.config.ENABLE_TIPS) {
            if (this.config.ENABLE_DDA_PROMO) {
                this.productCardPromo = this.config.ENABLE_PRIORITY_EP_PROMO ? (item.extendedPromo || item.highestEPPromowithTipAndDDA) : item.highestEPPromowithTipAndDDA;
            } else {
                this.productCardPromo = this.config.ENABLE_PRIORITY_EP_PROMO ? (item.extendedPromo || item.highestEPPromoWithTip) : item.highestEPPromoWithTip;
            }
        } else if (this.config.ENABLE_DDA_PROMO) {
            this.productCardPromo = item.highestEPPromoWithDDA;
        } else {
            this.productCardPromo = item.extendedPromo;
        }

        product.brand = item.brand;
        product.deviceName = item.name;
        product.preOrder = item.status === Availability.PREORDER;
        product.priceAfterPromotion = this.productCardPromo?.promotionPrice.toString();
        product.priceBeforePromotion = item.prices.find((priceOption: PriceOption) => priceOption.isFinanced).originalPrice.toString();
        product.onetimePrice = item.prices.find((priceOption: PriceOption) => priceOption.isFull).originalPrice.toString();
        product.contractTerm = `For ${item.prices.find((priceOption: PriceOption) => priceOption.isFinanced).term} months, 0% APR`;
        product.productImage = {
            small: {
                base: item.image.url,
                useBoundingBox: true,
                trimTransparency: true
            }
        };

        return product;
    }
}
