<section class="{{sectionContainer}}" [ngClass]="{'has-background': hasFieldOrEditable(rendering.fields.showBackground)}">
    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
        <h2 class="cards-header" *scRichText="rendering.fields.header"></h2>
    </ng-container>
    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.subheader)">
        <h2 class="cards-subheader" *scRichText="rendering.fields.subheader"></h2>
    </ng-container>
    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.body)">
        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.body)" class="cards-body" [field]="rendering.fields.body"></xm-cms-text>
    </ng-container>
    <ng-container *ngIf="hasPlaceholder(rendering, 'cardsModuleCard')">
        <div class="swiper-container" [swiper]="carouselSettings">
            <div
                sc-placeholder
                name="cardsModuleCard"
                class="swiper-wrapper"
                [ngClass]="{ 'small': inputs.isSmall && !breakpoint.isSmall, 'medium': inputs.isMedium, 'large': inputs.isLarge && !breakpoint.isMedium }"
                (loaded)="placeholderLoaded()"
                [rendering]="rendering"
                [inputs]="inputs">
            </div>

            <div class="swiper-pagination"></div>
        </div>
    </ng-container>
</section>
<div *ngIf="hasFieldOrEditable(rendering.fields.showBackground)" class="background"></div>
