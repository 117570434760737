import { Injector, NgModule } from '@angular/core';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MemoryLocationConfig, UIRouter, UrlParts } from '@uirouter/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StatesModule, UIRouterModule } from '@uirouter/angular';
import { UIRouterRx } from '@uirouter/rx';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
// import { Visualizer } from '@uirouter/visualizer';
import { LottieModule } from 'ngx-lottie';

import { MarketingAppComponent } from 'components/app/app';
import { MarketingBaseComponent } from 'components/base/base';
import { MarketingHomeComponent } from 'components/home/home';
import { CoreModule } from 'core/module';
import { MARKETING_ROUTES } from './routes';
import { ParamType } from 'core/constants';
import { WindowReference } from 'services/window';
import { CmsPageData } from 'core/store/cms/model/cms';

import { MarketingVerticalSpacerComponent } from 'components/vertical-spacer/spacer';
import { MarketingTextOnlyComponent } from 'components/text-only/text-only';
import { MarketingImageBannerComponent } from 'components/image-banner/image-banner';
import { MarketingFlexibleImageBannerComponent } from 'components/flexible-image-banner/flexible-image-banner';
import { MarketingPopoverComponent } from 'components/popover/popover';
import { MarketingNavChatComponent } from 'components/shared/nav/chat/chat';
import { MarketingNavCartComponent } from 'components/shared/nav/cart/cart';
import { MarketingNavHamburgerComponent } from 'components/shared/nav/hamburger/hamburger';
import { MarketingNavLogoComponent } from 'components/shared/nav/logo/logo';
import { MarketingNavPrimaryComponent } from 'components/shared/nav/primary/primary';
import { MarketingNavMenuItemComponent } from 'components/shared/nav/menu-item/menu-item';
import { MarketingNavProfileComponent } from 'components/shared/nav/profile/profile';
import { MarketingHeroBannerComponent } from 'components/hero-banner/hero-banner';
import { MarketingSideBySideComponent } from 'components/side-by-side/side-by-side';
import { MarketingCardsComponent } from 'components/cards/cards';
import { MarketingCardComponent } from 'components/card/card';
import { MarketingPlanDetailsComponent } from 'components/plan-details/plan-details';
import { MarketingToutsComponent } from 'components/touts/touts';
import { MarketingToutComponent } from 'components/tout/tout';
import { ModalModule } from 'components/shared/modal/module';
import { SharedModule } from 'components/shared/module';
import { PreregComponent } from 'components/prereg/prereg';
import { SupportedDeviceComponent } from 'components/supported-device/supported-device';
import { QuestionAnswersComponent } from 'components/question-answers/question-answers';
import { MarketingFooterComponent } from 'components/shared/footer/footer';
import { MarketingFlexibleTextComponent } from './components/flexible-text/flexible-text';
import { MarketingFlexibleBackgroundTextComponent } from './components/flexible-background-text/flexible-background-text';
import { MarketingCoverageCheckerComponent } from './components/coverage-checker/coverage-checker';
import { MarketingFAQComponent } from 'components/faq/faq';
import { MarketingGridComponent } from 'components/grid/grid';
import { MarketingGridTextComponent } from 'components/grid/text/text';
import { MarketingPlanCompareComponent } from 'components/plan-compare/plan-compare';
import { MarketingDataOptionsComponent } from 'components/data-options/data-options';
import { PlanCarouselComponent } from 'components/plan-carousel/plan-carousel';
import { PlanVariantComponent } from 'components/plan-variant/plan-variant';
import { MarketingAnimationComponent } from 'components/anim-player/anim-player';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from 'core/store/reducers';
import { PromoBannerComponent } from 'components/promo-banner/promo-banner';
import { ResponsiveImageComponent } from 'components/responsive-image/responsive-image';
import { MarketingPolicyListModule } from 'components/policy-list/policy-list';
import { MarketingPolicyListTextComponent } from 'components/policy-list/text/text';
import { MarketingEmptyComponent } from 'components/empty/empty';
import { MaketingPolicyHeaderModule } from 'components/policy-header/policy-header';
import { MarketingPlanOptionsModule } from 'components/plan-options/plan-options';
import { MarketingPolicyBreadcrumbsModule } from 'components/policy-breadcrumbs/breadcrumbs';
import { NetworkMapComponent } from './components/network-map/network-map';
import { ProductByodCardComponent } from 'components/product-carousel/byod-card/byod-card';
import { ProductCarouselComponent } from 'components/product-carousel/carousel';
import { ProductCarouselModule } from 'components/product-carousel/module';
import { MarketingByodPromoComponent } from './components/byod-promo/byod-promo';
import { MarketingPolicyBodyComponent } from 'components/policy-body/body';
import { MarketingUnlPricingOpener } from 'components/unl-pricing-opener/unl-pricing-opener';
import { MarketingUnlPricingModal } from 'components/unl-pricing-modal/unl-pricing-modal';
import { MarketingUnlPricingModalGroup } from 'components/unl-pricing-modal/group/group';
import { NavigationTopMenuComponent } from 'components/shared/nav-topmenu/nav-topmenu.component';
import { NavMainmenuComponent } from 'components/shared/nav-mainmenu/nav-mainmenu.component';
import { NoPageExistsComponent } from 'components/no-page-exists/no-page-exists.component';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { DeviceComponent } from 'components/compatibility/device/device.component';
import { CarrierComponent } from 'components/compatibility/carrier/carrier.component';
import { ImeiComponent } from 'components/compatibility/imei/imei.component';
import { SimComponent } from 'components/compatibility/sim/sim.component';
import { ConfirmComponent } from 'components/compatibility/confirm/confirm.component';
import { SimResultComponent } from 'components/compatibility/sim-result/sim-result.component';
import { ResultComponent } from 'components/compatibility/result/result.component';
import { SavingsCalculatorComponent } from 'components/savings-calculator/savings-calculator.component';
import { WifiHotspotsMapComponent } from 'components/wifi-hotspots-map/wifi-hotspots-map.component';
import { FlexibleCardComponent } from 'src/app/flexible-card/flexible-card.component';
import { FlexibleSectionComponent } from 'src/app/flexible-section/flexible-section.component';
import { SearchComponent } from 'components/shared/search/search';
import { XmShopHealthCheckComponent } from 'components/healthcheck/healthcheck';
import { MarketingUnlPlanComponent } from 'components/unl-plan-compare/unl-plan';

export function playerFactory(): Promise<typeof import('lottie-web')> {
    return import('lottie-web');
}

export function handle404Error(_matchValue: boolean, _url: UrlParts, router: UIRouter): void {
    router.stateService.go('404', {}, { location: false });
}

export function redirectDefaultPage(_matchValue: boolean, _url: UrlParts, router: UIRouter): void {
    router.stateService.go('default', {}, { location: false });
}

export function configRouter(router: UIRouter, _injector: Injector, module: StatesModule): void {
    router.plugin(UIRouterRx);
    // router.plugin(Visualizer);

    const currentUrl = window.location.href;
    if (currentUrl.endsWith('/')) {
        const updatedUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/'));
        window.location.href = updatedUrl;
    }
    router.urlService.config.type(ParamType.encoded, {
        encode: (val: string) => decodeURIComponent(val.toString()),
        decode: (val: string) => decodeURIComponent(val.toString()),
        is: (val: string) => typeof val === 'string',
        equals: (a: string, b: string) => a === b
    });

    if (!WindowReference.isWindowAvailable) {
        // used for SSR since BrowserLocationConfig fails (aka no browser on the server)
        const memoryLocationConfig: MemoryLocationConfig = new MemoryLocationConfig();
        memoryLocationConfig.html5Mode = () => true;

        router.locationConfig = memoryLocationConfig;
    }

    module.states = MARKETING_ROUTES;
}

export let baseHref: string;
export let initialStateCmsData: CmsPageData;
if (WindowReference.isWindowAvailable) {
    const ssrRawJson: HTMLElement = document.getElementById('__JSS_STATE__');
    if (ssrRawJson) {
        const jssState: object = JSON.parse(ssrRawJson.innerHTML);
        baseHref = '/';
        initialStateCmsData = CmsPageData.create({ sitecorePage: jssState, pageId: '' });
    }
}

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    a11y: true
};

@NgModule({
    bootstrap: [ MarketingAppComponent ],
    declarations: [
        MarketingAppComponent,
        MarketingBaseComponent,
        MarketingHomeComponent,
        MarketingVerticalSpacerComponent,
        MarketingHeroBannerComponent,
        MarketingTextOnlyComponent,
        MarketingImageBannerComponent,
        MarketingFlexibleImageBannerComponent,
        MarketingPopoverComponent,
        MarketingNavChatComponent,
        MarketingNavCartComponent,
        MarketingNavHamburgerComponent,
        MarketingNavLogoComponent,
        MarketingNavPrimaryComponent,
        MarketingNavMenuItemComponent,
        MarketingNavProfileComponent,
        MarketingFAQComponent,
        MarketingSideBySideComponent,
        MarketingCardsComponent,
        MarketingCardComponent,
        MarketingToutsComponent,
        MarketingToutComponent,
        SupportedDeviceComponent,
        QuestionAnswersComponent,
        MarketingCoverageCheckerComponent,
        MarketingPlanDetailsComponent,
        PreregComponent,
        MarketingFooterComponent,
        MarketingDataOptionsComponent,
        MarketingFlexibleTextComponent,
        MarketingFlexibleBackgroundTextComponent,
        PlanCarouselComponent,
        PlanVariantComponent,
        NetworkMapComponent,
        MarketingGridComponent,
        MarketingGridTextComponent,
        MarketingPlanCompareComponent,
        MarketingAnimationComponent,
        PromoBannerComponent,
        ResponsiveImageComponent,
        MarketingPolicyListModule,
        MarketingPolicyListTextComponent,
        MaketingPolicyHeaderModule,
        MarketingPlanOptionsModule,
        MarketingPolicyBreadcrumbsModule,
        MarketingEmptyComponent,
        MarketingByodPromoComponent,
        MarketingPolicyBodyComponent,
        MarketingUnlPricingOpener,
        MarketingUnlPricingModal,
        MarketingUnlPricingModalGroup,
        // New Navigation Components
        NavigationTopMenuComponent,
        NavMainmenuComponent,
        SearchComponent,
        NoPageExistsComponent,
        DeviceComponent,
        CarrierComponent,
        ImeiComponent,
        SimComponent,
        ResultComponent,
        ConfirmComponent,
        SimResultComponent,
        SavingsCalculatorComponent,
        WifiHotspotsMapComponent,
        FlexibleCardComponent,
        FlexibleSectionComponent,
        XmShopHealthCheckComponent,
        MarketingUnlPlanComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'marketing' }),
        CoreModule,
        ModalModule,
        SharedModule,
        SwiperModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        ProductCarouselModule,
        Angular2PromiseButtonModule,
        StoreModule.forRoot(reducers, { metaReducers, initialState: {
            cmsPageData: [ initialStateCmsData ]
        } }),
        JssModule.withComponents([
            { name: 'HomeModuleDoNotUse', type: MarketingHomeComponent },
            { name: 'VerticalSpacerComponent', type: MarketingVerticalSpacerComponent },
            { name: 'TextOnlyComponent', type: MarketingTextOnlyComponent },
            { name: 'EmptyComponent', type: MarketingEmptyComponent },
            { name: 'ImageBannerComponent', type: MarketingImageBannerComponent },
            { name: 'FlexibleImageBannerComponent', type: MarketingFlexibleImageBannerComponent },
            { name: 'HeroBannerComponent', type: MarketingHeroBannerComponent },
            { name: 'HeroBannerModule', type: MarketingHeroBannerComponent },

            // New Navigation Bar
            { name: 'NavigationTopMenuModule', type: NavigationTopMenuComponent },
            { name: 'NavigationMainMenuModule', type:  NavMainmenuComponent },
            { name: 'NavBarComponent', type: MarketingNavPrimaryComponent },
            { name: 'PopoverComponent', type: MarketingPopoverComponent },
            { name: 'UnlPricingModalComponent', type: MarketingUnlPricingOpener },
            { name: 'UnlPricingGroupComponent', type: MarketingUnlPricingModalGroup },
            { name: 'PreregComponent', type: PreregComponent },
            { name: 'SupportedDeviceComponent', type: SupportedDeviceComponent },
            { name: 'FooterComponent', type: MarketingFooterComponent },
            { name: 'FlexibleTextModule', type: MarketingFlexibleTextComponent },
            { name: 'FlexibleBackgroundTextModule', type: MarketingFlexibleBackgroundTextComponent },
            { name: 'FAQComponent', type: MarketingFAQComponent },
            { name: 'QuestionAnswersComponent', type: QuestionAnswersComponent },
            { name: 'MarketingSideBySideComponent', type: MarketingSideBySideComponent },
            { name: 'CoverageModule', type: MarketingCoverageCheckerComponent },
            { name: 'PlanCompareModule', type: MarketingPlanCompareComponent },
            { name: 'CardsModule', type: MarketingCardsComponent },
            { name: 'CardComponent', type: MarketingCardComponent },
            { name: 'GridModule', type: MarketingGridComponent },
            { name: 'GridTextComponent', type: MarketingGridTextComponent },
            { name: 'PlanDetailsModule', type: MarketingPlanDetailsComponent },
            { name: 'ToutsModule', type: MarketingToutsComponent },
            { name: 'ToutComponent', type: MarketingToutComponent },
            { name: 'DataOptionsModule', type: MarketingDataOptionsComponent },
            { name: 'PlanCarouselModule', type: PlanCarouselComponent },
            { name: 'PlanVariantComponent', type: PlanVariantComponent },
            { name: 'AnimationComponent', type: MarketingAnimationComponent },
            { name: 'PromoBannerComponent', type: PromoBannerComponent },
            { name: 'ResponsiveImageComponent', type: ResponsiveImageComponent },
            { name: 'PolicyListModule', type: MarketingPolicyListModule },
            { name: 'PolicyListTextComponent', type: MarketingPolicyListTextComponent },
            { name: 'PolicyHeaderModule', type: MaketingPolicyHeaderModule },
            { name: 'PlanOptionsModule', type: MarketingPlanOptionsModule },
            { name: 'NetworkMapModule', type: NetworkMapComponent },
            { name: 'PolicyBreadcrumbsModule', type: MarketingPolicyBreadcrumbsModule },
            { name: 'ByodCardComponent', type: ProductByodCardComponent },
            { name: 'ProductCarouselModule', type: ProductCarouselComponent },
            { name: 'ByodPromoModule', type: MarketingByodPromoComponent },
            { name: 'PolicyBodyModule', type: MarketingPolicyBodyComponent },
            { name: 'DeviceModule', type: DeviceComponent },
            { name: 'CarrierModule', type: CarrierComponent },
            { name: 'ImeiModule', type: ImeiComponent },
            { name: 'ResultModule', type: ResultComponent },
            { name: 'SimModule', type: SimComponent },
            { name: 'ConfirmModule', type: ConfirmComponent },
            { name: 'SimResultModule', type: SimResultComponent },
            { name: 'SavingsCalculatorModule', type: SavingsCalculatorComponent },
            { name: 'WifiHotspotsMapModule', type: WifiHotspotsMapComponent },
            { name: 'FlexibleCardComponent', type: FlexibleCardComponent },
            { name: 'FlexibleSectionComponent', type: FlexibleSectionComponent },
            { name: 'XmShopHealthCheckComponent', type: XmShopHealthCheckComponent },
            { name: 'UnlPlanTableModule', type: MarketingUnlPlanComponent }
        ]),
        UIRouterModule.forRoot({ states: MARKETING_ROUTES, otherwise: handle404Error, initial: redirectDefaultPage, config: configRouter }),
        LottieModule.forRoot({ player: playerFactory }),
        LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR })
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        WindowReference,
        // {
        //     provide: NgModuleFactoryLoader,
        //     useClass: SystemJsNgModuleLoader
        // },
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        }
    ]
})
export class MarketingModule {}
